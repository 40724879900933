<template>
    <b-card>
    
        <div >
            <h4>Shipping Method</h4>
        </div>
        <p>Update shipping method</p>
        <b-row>
            <b-col cols="4">
            <selector 
            @selectionChanged="selectionChanged"
            :selected="selectedMethod"
            :options="shippingMethods" style="min-width:15rem" />
        </b-col>
        </b-row>
        <b-row class="mt-1">
                <b-col cols="4">
                <b-button @click="handleSave" variant="primary">Save Changes</b-button>
                </b-col>
        </b-row>
    </b-card>
</template>

<script >
import {BCard,BRow,BCol,BButton} from 'bootstrap-vue'
import selector from '../../components/CustomerSelect.vue';

export default {
    components:{
    BCard,
    BRow,BCol,BButton,
    selector
    },
    props:{
        selectedMethod:{
            required:true
        },
        handleSave:{
            required:true
        },
        selectionChanged:{
            required:true
        },
        shippingMethods:{
            required:true
        }
    },
    setup() {
        
    },
}
</script>
