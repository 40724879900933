<template>
    <b-card>
    
        <div >
            <h4>Shipping Origin</h4>
        </div>
        <div>
            <b-row>
                <b-col cols="4">
                    <label>Branch Name</label>
                    <b-form-input v-model="shippingOrigin.branchName" />
                </b-col>
                <b-col cols="4">
                    <label>Branch Code</label>
                    <b-form-input v-model="shippingOrigin.brancheCode"/>
                </b-col>
                <b-col cols="4">
                    <label>Company Name</label>
                    <b-form-input  v-model="shippingOrigin.comapnyName" />
                </b-col>
                
            </b-row>
            <b-row class="mt-1">
                <b-col cols="4">
                    <label>Street</label>
                    <b-form-input  v-model="shippingOrigin.street"/>
                </b-col>
                <b-col cols="4">
                    <label>City</label>
                    <b-form-input v-model="shippingOrigin.city"/>
                </b-col>
                <b-col cols="4">
                    <label>Postal Code</label>
                    <b-form-input v-model="shippingOrigin.postalCode" />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="4">
                    <label>Country Code</label>
                    <b-form-input v-model="shippingOrigin.street"/>
                </b-col>
                <b-col cols="4">
                    <label>Province/State Code</label>
                    <b-form-input v-model="shippingOrigin.stateOrProvinceCode"/>
                </b-col>
                <b-col cols="4">
                    <label>Phone Number</label>
                    <b-form-input v-model="shippingOrigin.phone"/>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="4">
                <b-button @click="handleSave('shippingOrigin')" variant="primary">Save Changes</b-button>
                </b-col>
                
            </b-row>
        </div>
    </b-card>
</template>

<script >
import {BCard,BFormInput,BCol,BRow,BButton} from 'bootstrap-vue'

export default {
    components:{
    BCard,
    BButton,
    BFormInput,
    BCol,
    BRow
    },
    props: {
    shippingOrigin: {
      required: true,
    },
    handleSave: {
      required: true,
    },
  },
    setup() {
        
    },
}
</script>
