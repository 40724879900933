<template>
    <b-card>
        <div>
          
        </div>
        <div id="hello">
            <h4>General</h4>
        </div>
        <div style="display: flex; flex-direction: row">
      <b-col cols="4" style="padding-left: 0">
        <label>Store Logo</label>
        <div class="d-flex flex-row align-items-center">
          <img
            class="image"
            :src="
              invoiceSettings.storeLogo
                ? invoiceSettings.storeLogo
                : 'http://via.placeholder.com/150x50'
            "
          />
          <input id="uploadFile" type="file" hidden />
          <b-button
            size="sm"
            class="ml-1"
            variant="primary"
            @click="() => handleImageInput('logo')">
            Upload
          </b-button>
        </div>
      </b-col>
    </div>
    <div>
      <div class="mt-1">
        <label>Store Title</label>
        <b-form-input v-model="invoiceSettings.storeTitle" />
      </div>
      <div class="d-flex flex-wrap">
        <b-col cols="4" style="padding-left: 0px">
          <div class="mt-1">
            <label>Tax Number</label>
            <b-form-input v-model="invoiceSettings.taxNumber" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Tax Department</label>
            <b-form-input v-model="invoiceSettings.taxDepartment" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Tax Address</label>
            <b-form-input v-model="invoiceSettings.taxAddress" />
          </div>
        </b-col>
        </div>
        </div>
        <!-- <b-button
        variant="primary"
        id="addButton">Add Text</b-button>
        <b-button
        class="ml-2"
        variant="primary"
        id="addImage">Add Image</b-button>
        <b-button
        class="ml-2"
        variant="primary"
        id="updateImage">update Image</b-button>
        <input
        id="uploadFile"
        style="background-color: #ddd;padding: 5px;"
        type="file"
        />
        <b-form-input
        id="textInput"
        /> -->
        <b-button variant="primary" class="mt-1" @click="handleSave">
      Save Changes
    </b-button>
    </b-card>
</template>

<script >
import {BCard,BButton,BFormInput,BCol} from 'bootstrap-vue'
export default {
    components:{
    BCard,
    BCol,
    BButton,
    BFormInput
    },
    props: {
    invoiceSettings: {
      required: true,
    },
    handleImageInput: {
      required: true,
    },
    handleSave: {
      required: true,
    },
  },
    setup() {
    
    },
}
</script>
<style>
.image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
::file-selector-button {
  display: none;
}
</style>
