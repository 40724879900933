import { onMounted, onUnmounted, reactive } from "@vue/composition-api"
import axios from '@axios';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import { useToast } from "vue-toastification/composition";

export default function useStoreView() {

    const toast = useToast();

    const SHIPPING_METHOD = [
        {name:'LOCAL_SHIPPING',value:1},
        {name:'SAME_DAY_DELIVERY',value:2,},
        {name:'OVERNIGHT_DELIVERY',value:3},
        {name:'EXPEDITED_SHIPPING',value:4},
    ];

     const PAYMENT_TYPES = [
       "CREDITCARD",
      
        "BANKTRANSFER",
         
         "ONDOORPAY",
    //   {  name: "CREDITCARD"},
      
    //  { name: "BANKTRANSFER"},
      
    //   {name: "ONDOORPAY"},
      
     ];

    const state = reactive({
        tabIndex: 0,
        formData: '',
        paymentMethods:[],
        selectedPaymentMethod:null,
        selectedShippingMethod:null,
        shippingMethod:'',
        shippingOrigin: {
            id:'',
            shippingOrigin:{
            branchName: "",
            brancheCode: "",
            comapnyName: "",
            street: "",
            city: "",
            countryCode: "",
            postalCode: "",
            stateOrProvinceCode: "",
            phone: "",
            deleted: 0
            }
        },
        invoiceSettings: {
            storeLogo: '',
            storeTitle: '',
            taxNumber: 0,
            taxDepartment: '',
            taxAddress: '',
            invoiceSerial: '',
            invoiceNumber: 0,
            currentInvoiceNumber: 0,
            headline: '',
            footer: '',
            InvoiceBackground: '',
            hasMultipleBranches: false,
            openforOnlinePurchase: false,
        }
    })

    function setShippingMethod(item){
        //console.log('item',item);
        state.selectedShippingMethod = item
    }

    function setPaymentMethod(item){
        //console.log('item',item);
        state.selectedPaymentMethod = item
    }

    function getShippingMethod(){
        axios.get("admin/invoice/store/getShippingMethod").then(res=>{
            //console.log('sssaass',res.data.data);
            const temp = SHIPPING_METHOD.find(i=>Number(res.data.data.shippingMethod) == i.value);
            state.selectedShippingMethod = temp
        })
    }

    function getPaymentMethods(){
        axios.get("admin/invoice/store/paymentMethod").then(res=>{
            //console.log('sssaass',res.data.data);
            state.selectedPaymentMethod = res.data.data.paymentMethod
            //console.log('state.selectedPaymentMethod ',state.selectedPaymentMethod);
            // state.selectedPaymentMethod = {
            //     name:res.data.data.paymentMethod
            // }
        //console.log('itemitemitem',state.selectedPaymentMethod);

        })
    }


    function getShippingOrigin() {
        axios.get("admin/invoice/store/getShippingOrigin").then(res=>{
            let temp = res.data.data;
            const ress = JSON.parse(temp.shippingOrigin);
            temp.shippingOrigin = ress;
            state.shippingOrigin = temp
        })
    }

    getShippingOrigin();

    const saveStoreOptions = (type) => {
        try {
            if (state.invoiceSettings.id) {
                if(type=='shippingOrigin'){
                axios.put(`admin/invoice/store/shippingOrigin/${state.shippingOrigin.id}`, {
                    shippingOrigin:state.shippingOrigin.shippingOrigin
                })
                }
                else if(type=='paymentMethod'){
                    //console.log('state.sss',state.selectedPaymentMethod);
                    axios.put(`admin/invoice/store/paymentMethod/${state.shippingOrigin.id}`, {
                        paymentMethod:state.selectedPaymentMethod
                    })
                    }
                else{
                    axios.put(`admin/invoice/store/shippingMethod/${state.shippingOrigin.id}`, {
                        shippingMethod:state.selectedShippingMethod.value
                    })
                }
            }
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Updated successfully',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                },
            });
        } catch (error) {
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Error performing operation`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                },
            });
        }
    }

    const handleSave = () => {
        //console.log(state.invoiceSettings);
        // //console.log($route);
        try {
            if (state.invoiceSettings.id) {
                axios.put(`admin/invoice/store/${state.invoiceSettings.id}`, {
                    ...state.invoiceSettings
                })
            } else {
                axios.post('admin/invoice/store/new', {
                    ...state.invoiceSettings
                })
            }
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: ` ${state.invoiceSettings.id ? 'Store updated successfully' : 'Store created successfully'}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                },
            });
        } catch (error) {
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Error performing operation`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                },
            });
        }
    }

    const handleImageInput = async (type) => {
        const res = document.querySelector('#uploadFile');
        res.click()
        res.onchange = function (event) {
            const reader = new FileReader();
            //console.log('event', event);
            state.formData = new FormData();
            state.formData.append('file', event.target.files[0]);
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = async function (e) {
                //console.log('eee', e.target.result);
                if (type == 'logo') {
                    state.invoiceSettings.storeLogo = e.target.result;
                } else {
                    state.invoiceSettings.InvoiceBackground = e.target.result;
                }
                
                var host=process.env.VUE_APP_API_URL;

                const response = await axios.post(
                    host+'/upload/admin',
                    state.formData,
                    {
                        headers: {
                            'client-auth-token': localStorage.getItem('clientToken'),
                            'content-type': 'multipart/form-data',
                            'x-access-token': localStorage.getItem('accessToken'),
                        },
                    }
                );
                if (type == 'logo') {
                    state.invoiceSettings.storeLogo = response.data.url;
                } else {
                    state.invoiceSettings.InvoiceBackground = response.data.url;
                }
            }
        }

    }


    onMounted(() => {
        axios.get('admin/invoice/store/current').then(res => {
            state.invoiceSettings = res.data.data
            state.invoiceSettings.hasMultipleBranches = res.data.data.hasMultipleBranches ? true : false;
            state.invoiceSettings.openforOnlinePurchase = res.data.data.openforOnlinePurchase ? true : false;
        })

        getPaymentMethods()
        getShippingMethod()

        var element = {};
        let selected = {};
        var selector = document.querySelector('#hello');
        var addButton = document.querySelector("#addButton");
        var addImageButton = document.querySelector("#addImage");
        var updateImage = document.querySelector("#updateImage");
        var uploadFile = document.querySelector("#uploadFile");
        var handleInput = document.querySelector("#textInput");

        document.addEventListener('click', (event) => {
            //console.log('clicked', event.target.id);
            if (event.target.id != 'uploadFile' && !event.target.src) {
                selected.image = null;
                selected.style.border = 'none'
            }
        })

        addImageButton.addEventListener('click', () => {
            element = document.createElement("div");
            element.image = document.createElement("img");
            // element.button = document.createElement("button");
            // element.button.style.position = "absolute"
            // element.button.style.zIndex = 1
            // element.button.textContent = "update";

            // updateImage(element)
            const mainDiv = selector.appendChild(element);
            // mainDiv.appendChild(element.button);
            const imageMain = mainDiv.appendChild(element.image);
            selected = mainDiv;
            moveElement(mainDiv)
            updateElement(mainDiv)
            //  element.image.appendChild(element.button);

            // element.appendChild(element.button)
            // selector.appendChild(element.button)
        })
        uploadFile.addEventListener('change', (event) => {
            //console.log('chaned', event.target.files[0]);

            const file = new FileReader()
            file.readAsDataURL(event.target.files[0])
            file.onload = function (e) {
                //console.log(file.result);
                // //console.log('inUpload',res,selected);
                element.image = document.createElement("img");
                element.image.style.position = "absolute";
                element.image.style.width = '100px';
                element.image.style.height = '100px'
                //console.log('selected', selected);
                element.image.src = e.target.result;
                if (selected.image) {
                    selected.image.src = e.target.result
                    selected.image = null;
                    selected.style.border = 'none'
                } else {
                    //console.log('added');
                    selector.appendChild(element.image)
                }
                moveElement(element.image)
                updateElement(element.image)

                // selected = element.image
                // selected.src = e.target.result

            };
            // selected.src = event.target.files[0].name
            // //console.log('file',file);
        })
        function removeElement(element) {
            element.addEventListener('click', (event) => {
                // //console.log('removing', element.parentNode.remove());
                // selected = element;
                // selected.parentNode.remove()
                //console.log(selected);
            })
        }
        function updateElement(element) {
            element.addEventListener('click', (event) => {
                // const selected = null;
                // //console.log('removing', element.parentNode.remove());
                //console.log(selected);
                // selected = element
                // selected = event.target.parentNode;
                selected.style.border = '1px solid red'
                //console.log(selected.children);
                // event.target.parentNode.children[1].src = 'https://images.unsplash.com/photo-1506038634487-60a69ae4b7b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZnJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                // selected.parentNode.remove()
            })
        }
        addButton.addEventListener('click', () => {
            element = document.createElement("h4");
            element.textContent = "hello"
            element.style.position = "absolute"
            element.style.left = "100px"
            selected = element;
            //console.log(element.innerHTML);
            handleInputChange(element)
            moveElement(element)
            selector.appendChild(element)
        })

        function handleInputChange(element) {
            handleInput.addEventListener('change', (event) => {
                //console.log('selected', selected.innerHTML, event.target.value);
                selected.innerHTML = event.target.value
            })
        }

        function updateImage(element) {
            //console.log('object', element);
            // selected = element
            //console.log(element);
            selected.src = 'https://images.unsplash.com/photo-1506038634487-60a69ae4b7b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZnJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60';
        }
        function moveElement(element) {

            let pos = {};
            element.addEventListener('click', (event) => {
                //console.log('forTesting', event.target);
                //console.log('ss');
                selected = event.target
                if (event.target.src) {
                    selected.image = event.target

                }
                // event.target.src = 'https://images.unsplash.com/photo-1506038634487-60a69ae4b7b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZnJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60';
                // element.remove()
            })
            element.addEventListener('mousedown', (e) => {
                pos.x = e.clientX;
                pos.y = e.clientY;
                document.onmouseup = function () {
                    document.onmousemove = null;
                };
                document.onmousemove = function (e) {
                    //console.log('e.clientX', e.clientX, e.clientY, element.offsetLeft);
                    pos.ux = pos.x - e.clientX;
                    pos.uy = pos.y - e.clientY;
                    pos.x = e.clientX;
                    pos.y = e.clientY;
                    element.style.left = element.offsetLeft - pos.ux + "px";
                    element.style.top = element.offsetTop - pos.uy + "px";
                };
            })
            //console.log(selector.innerHTML);
        }
    })

    return {
        state,
        handleSave,
        PAYMENT_TYPES,
        SHIPPING_METHOD,
        setShippingMethod,
        setPaymentMethod,
        saveStoreOptions,
        handleImageInput
    }
}