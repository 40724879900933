<template>
  <b-card>
    <div>
      <h4>Invoice Settings</h4>
    </div>
    <!-- <div style="display: flex; flex-direction: row">
      <b-col cols="4" style="padding-left: 0">
        <label>Store Logo</label>
        <div class="d-flex flex-row align-items-center">
          <img
            class="image"
            :src="
              invoiceSettings.storeLogo
                ? invoiceSettings.storeLogo
                : 'http://via.placeholder.com/150x50'
            "
          />
          <input id="uploadFile" type="file" hidden />
          <b-button
            size="sm"
            class="ml-1"
            variant="primary"
            @click="() => handleImageInput('logo')"
            >Upload</b-button
          >
        </div>
      </b-col>
    </div> -->
    <div>
      <!-- <div class="mt-1">
        <label>Store Title</label>
        <b-form-input v-model="invoiceSettings.storeTitle" />
      </div> -->
      <div class="d-flex flex-wrap">
        <!-- <b-col cols="4" style="padding-left: 0px">
          <div class="mt-1">
            <label>Tax Number</label>
            <b-form-input v-model="invoiceSettings.taxNumber" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Tax Department</label>
            <b-form-input v-model="invoiceSettings.taxDepartment" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Tax Address</label>
            <b-form-input v-model="invoiceSettings.taxAddress" />
          </div>
        </b-col> -->
        <b-col cols="4" style="padding-left: 0">
          <div class="mt-1">
            <label>Invoice Serial</label>
            <b-form-input v-model="invoiceSettings.invoiceSerial" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Invoice Number</label>
            <b-form-input v-model="invoiceSettings.invoiceNumber" />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Current Invoice Number</label>
            <b-form-input v-model="invoiceSettings.currentInvoiceNumber" />
          </div>
        </b-col>
        <b-col cols="4" style="padding-left: 0">
          <div class="mt-1">
            <label>Multiple Branches</label>
            <b-form-checkbox
              id="hasMultipleBranches"
              :checked="invoiceSettings.hasMultipleBranches"
              name="has-multiple-branches"
              v-model="invoiceSettings.hasMultipleBranches"
              switch
            ></b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mt-1">
            <label>Online Purchase</label>
            <b-form-checkbox
              id="open-for-online-purchase"
              :checked="invoiceSettings.openforOnlinePurchase"
              name="open-for-online-purchase"
              v-model="invoiceSettings.openforOnlinePurchase"
              switch
            ></b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12" style="padding-left: 0;" class="mt-1">
        <label>Background Image</label>
        <div class="d-flex flex-row align-items-center">
          <img
            class="imageBackground"
            :src="
              invoiceSettings.InvoiceBackground
                ? invoiceSettings.InvoiceBackground
                : 'http://via.placeholder.com/1140x392'
            "
          />
          <input id="uploadFile" type="file" hidden />
      
          
        </div>
        <b-button
            size="sm"
            class="mt-1"
            variant="primary"
            @click="() => handleImageInput('background')"
            >Upload</b-button>
      </b-col>
        <b-col cols="12" style="padding-left: 0">
          <div class="mt-1">
            <label>Footer Text</label>
            <b-form-textarea v-model="invoiceSettings.footer" />
          </div>
        </b-col>
      </div>
    </div>
    <b-button variant="primary" class="mt-1" @click="handleSave">
      Save Changes
    </b-button>
  </b-card>
</template>

<script >
import {
  BCard,
  BButton,
  BFormInput,
  BCol,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BFormInput,
  },
  props: {
    invoiceSettings: {
      required: true,
    },
    handleImageInput: {
      required: true,
    },
    handleSave: {
      required: true,
    },
  },

  setup() {
    //console.log(this.invoiceSettings);
  },
};
</script>
<style >
.image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
.imageBackground {
  width:70%;
  height:30rem;
  border-radius: 8px;
}
label {
  font-size: 14px;
}
</style>
